import { render, staticRenderFns } from "./liveBaseInfo.vue?vue&type=template&id=343a3689&scoped=true"
import script from "./liveBaseInfo.vue?vue&type=script&lang=js"
export * from "./liveBaseInfo.vue?vue&type=script&lang=js"
import style0 from "./liveBaseInfo.vue?vue&type=style&index=0&id=343a3689&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "343a3689",
  null
  
)

export default component.exports